*{
  overflow-x: hidden;
}

.bigcircle{
  width: 388px;
  height: 388px;
  border: 1px solid #CBCBCB;
  position: absolute ;
  top: 900px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
}

.smallcircle{
  width: 60px;
  height: 60px;
  border-radius: 50%;
    position: absolute;
  top: 20px;
  left: 5rem;
  right: 0;
  overflow: hidden;
  margin: auto;
}
@media only screen and (max-width: 425px) {
  .bigcircle{
    width: 300px;
    height: 300px;
 
  }
  
}
@media only screen and (max-width: 320px) {
  .bigcircle{
    width: 250px;
    height: 250px;
 
  }
  
}